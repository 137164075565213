button{
    background-color: green; 
}
.Navbar-Container{
    position: absolute;
    background: #000;
}
.Navbar-Button{
    margin: 1rem;
    cursor:pointer;
}
.Navbar{
    display: flex;
    justify-content: right;
}
