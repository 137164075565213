*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Quicksand', sans-serif;
  }
  .Login-Container{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #000;
  }
  section{
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    flex-wrap: wrap;
    overflow: hidden;
  }
  section::before 
  {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(#000,#0f0,#000);
    animation: animate 5s linear infinite;
  }
  @keyframes animate 
  {
    0%
    {
      transform: translateY(-100%);
    }
    100%
    {
      transform: translateY(100%);
    }
  }
  .sigin
  {
    position: absolute;
    width: 400px;
    background: #222;  
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    border-radius: 4px;
    box-shadow: 0 15px 35px rgba(0,0,0,9);
  }
  .content 
  {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
  }
  .signin-header
  {
    font-size: 2em;
    color: #0f0;
    text-transform: uppercase;
  }
  .form{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .inputBox{
    position: relative;
    width: 100%;
  }
  input{
    position: relative;
    width: 100%;
    background: #333;
    border: none;
    outline: none;
    padding: 25px 10px 7.5px;
    border-radius: 4px;
    color: #fff;
    font-weight: 500;
    font-size: 1em;
  }
  input[type="submit"]{
    padding: 10px;
    background: #0f0;
    color: #000;
    font-weight: 600;
    font-size: 1.35em;
    letter-spacing: 0.05em;
    cursor: pointer;
  }
  input[type="submit"]:active
{
  opacity: 0.6;
}
.Navbar-Button{
  background: none;
  border:none;
  font-size: 2rem;
  color: white;
}
.Navbar-Button:hover{
  color: green;
}