.Post-Container {
	min-height: 100vh;
	min-width: 100vw;
	background: black;
	display: flex;
	justify-content: center;
	align-items: center;
}
textarea {
	background: #444;
	width: 500px;
	height: 300px;
	resize: none;
	border: none;
	overflow: hidden;
	color: white;
	font-size: 1.2rem;
}
.Post-Form {
	position: absolute;
	background: #333;
	display: grid;
	height: auto;
	width: fit-content;
	grid-template-columns: auto;
	grid-template-rows: auto;
	gap: 10px;
	padding: 1.5rem;
	grid-template-areas:
		'PostHeader PostHeader PostNavbar'
		'PostText1 PostText2 .'
		'ShowTextENG ShowTextGER PostFilepath'
		'PostFront PostSubHeader PostSkills '
		'Post-Images PostLink Post-Button';
}
.skills-button {
	border: none;
	background: none;
	color: white;
}
.skills-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.images-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.Post-Front {
	grid-area: PostFront;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
}
.Post-Front input {
	width: 300px;
	background: #444;
	padding: 15px;
}
.Post-Skills {
	grid-area: PostSkills;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
}
.Post-Skills input {
	width: 300px;
	background: #444;
	padding: 15px;
}
.skills-p {
	background: #444;
	border-radius: 3px;
	padding: 5px;
	margin: 0 2.5px 10px 2.5px;
	color: #298e46;
}
.Post-Images {
	grid-area: PostSkills;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
}
.Post-Images input {
	width: 300px;
	background: #444;
	padding: 15px;
}
.images-p {
	background: #444;
	border-radius: 3px;
	padding: 5px;
	margin: 0 2.5px 10px 2.5px;
	color: #298e46;
}
.Show-Text-GER {
	grid-area: ShowTextGER;
}
.Show-Text-ENG {
	grid-area: ShowTextENG;
}
.Post-Images {
	grid-area: Post-Images;
}
.Post-Header {
	display: flex;
	justify-content: center;
	align-items: center;
	grid-area: PostHeader;
	color: #298e46;
}
.Post-SubHeader {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	grid-area: PostSubHeader;
}
.Post-SubHeader input {
	width: 300px;
	background: #444;
	padding: 15px;
}
.Post-Filepath {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	grid-area: PostFilepath;
}
.Post-Filepath input {
	width: 300px;
	background: #444;
	padding: 15px;
}
.Post-Text-1 {
	grid-area: PostText1;
}
.Post-Text-2 {
	grid-area: PostText2;
}
.Post-Link {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	grid-area: PostLink;
}
.Post-Link input {
	width: 300px;
	background: #444;
	padding: 15px;
}
.Post-Button {
	width: 13rem;
	grid-area: Post-Button;
	appearance: none;
	border: 1px solid rgba(27, 31, 35, 0.15);
	border-radius: 6px;
	box-shadow: rgba(27, 31, 35, 0.1) 0 1px 0;
	box-sizing: border-box;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	font-family: -apple-system, system-ui, 'Segoe UI', Helvetica, Arial,
		sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	padding: 6px 16px;
	position: relative;
	text-align: center;
	text-decoration: none;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	vertical-align: middle;
	white-space: nowrap;
}
.Post-Navbar {
	grid-area: PostNavbar;
}
.Post-Button:focus:not(:focus-visible):not(.focus-visible) {
	box-shadow: none;
	outline: none;
}

.Post-Button:hover {
	background-color: #2c974b;
}

.Post-Button:focus {
	box-shadow: rgba(46, 164, 79, 0.4) 0 0 0 3px;
	outline: none;
}

.Post-Button:disabled {
	background-color: #94d3a2;
	border-color: rgba(27, 31, 35, 0.1);
	color: rgba(255, 255, 255, 0.8);
	cursor: default;
}

.Post-Button:active {
	background-color: #298e46;
	box-shadow: rgba(20, 70, 32, 0.2) 0 1px 0 inset;
}
.dot-spin {
	position: relative;
	width: 10px;
	height: 10px;
	margin-left: 2rem;
	border-radius: 5px;
	background-color: transparent;
	color: transparent;
	box-shadow: 0 -18px 0 0 #298e46, 12.727926px -12.727926px 0 0 #298e46,
		18px 0 0 0 #298e46, 12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0),
		0 18px 0 0 rgba(152, 128, 255, 0),
		-12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0),
		-18px 0 0 0 rgba(152, 128, 255, 0),
		-12.727926px -12.727926px 0 0 rgba(152, 128, 255, 0);
	animation: dot-spin 1.5s infinite linear;
}

@keyframes dot-spin {
	0%,
	100% {
		box-shadow: 0 -18px 0 0 #298e46, 12.727926px -12.727926px 0 0 #298e46,
			18px 0 0 0 #298e46, 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
			0 18px 0 -5px rgba(152, 128, 255, 0),
			-12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
			-18px 0 0 -5px rgba(152, 128, 255, 0),
			-12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
	}
	12.5% {
		box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
			12.727926px -12.727926px 0 0 #298e46, 18px 0 0 0 #298e46,
			12.727926px 12.727926px 0 0 #298e46, 0 18px 0 -5px rgba(152, 128, 255, 0),
			-12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
			-18px 0 0 -5px rgba(152, 128, 255, 0),
			-12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
	}
	25% {
		box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
			12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 0 #298e46,
			12.727926px 12.727926px 0 0 #298e46, 0 18px 0 0 #298e46,
			-12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
			-18px 0 0 -5px rgba(152, 128, 255, 0),
			-12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
	}
	37.5% {
		box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
			12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
			18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 0 #298e46,
			0 18px 0 0 #298e46, -12.727926px 12.727926px 0 0 #298e46,
			-18px 0 0 -5px rgba(152, 128, 255, 0),
			-12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
	}
	50% {
		box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
			12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
			18px 0 0 -5px rgba(152, 128, 255, 0),
			12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 #298e46,
			-12.727926px 12.727926px 0 0 #298e46, -18px 0 0 0 #298e46,
			-12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
	}
	62.5% {
		box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
			12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
			18px 0 0 -5px rgba(152, 128, 255, 0),
			12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
			0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 0 #298e46,
			-18px 0 0 0 #298e46, -12.727926px -12.727926px 0 0 #298e46;
	}
	75% {
		box-shadow: 0 -18px 0 0 #298e46,
			12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
			18px 0 0 -5px rgba(152, 128, 255, 0),
			12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
			0 18px 0 -5px rgba(152, 128, 255, 0),
			-12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
			-18px 0 0 0 #298e46, -12.727926px -12.727926px 0 0 #298e46;
	}
	87.5% {
		box-shadow: 0 -18px 0 0 #298e46, 12.727926px -12.727926px 0 0 #298e46,
			18px 0 0 -5px rgba(152, 128, 255, 0),
			12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
			0 18px 0 -5px rgba(152, 128, 255, 0),
			-12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
			-18px 0 0 -5px rgba(152, 128, 255, 0),
			-12.727926px -12.727926px 0 0 #298e46;
	}
}
