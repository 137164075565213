.Home-Container {
	height: 100vh;
	width: 100vw;
	background-color: #000;
}
.Home-Navbar {
	width: 100vw;
	height: fit-content;
	display: flex;
	justify-content: right;
}
.Item-Container {
	margin-top: 2rem;
	margin-left: 3rem;
	background-color: #181818;
	box-shadow: 0 0 20px 10px #0f0;
	display: grid;
	grid-auto-columns: 1fr;
	grid-auto-rows: 1fr;
	grid-template-columns: 1.5fr 0.5fr;
	grid-template-rows: 1fr 1fr;
	width: 400px;
	height: 80px;
	gap: 0px 0px;
	grid-template-areas:
		'Item-Header Item-Button-one'
		'Item-Sub-Header Item-Button-two';
}
.Item-Img {
	width: 2rem;
}
.Header {
	margin: 0 0 0 1rem;
	color: #0f0;
}
.Item-Sub-Header {
	color: #0f0;
	grid-area: Item-Sub-Header;
}
.Item-Header {
	color: #0f0;
	grid-area: Item-Header;
}
.Item-Button-one {
	width: 2rem;
	grid-area: Item-Button-one;
	background: none;
	border: none;
}
.Item-Button-two {
	width: 2rem;
	grid-area: Item-Button-two;
	background: none;
	border: none;
}
.Button-Image {
	width: 2rem;
}
.Content-Container {
	display: grid;
	align-items: center;
	gap: 0px 0px;
}
.Projects-Container {
	margin-top: 15%;
	grid-area: Projects-Container;
}
.Posts-Container {
	margin-top: 15%;
	grid-area: Posts-Container;
}
button {
	cursor: pointer;
}
#Button-Container {
	display: flex;
	justify-content: right;
}
